import React, { useState } from 'react';
import EquipmentForm from '../components/EquipmentForm';
import FovCalculator from '../components/FovCalculator';
import HeaderElement from '../components/Header';
import FooterElement from '../components/Footer';

const HomePage: React.FC = () => {
  const [equipment, setEquipment] = useState({
    sensorWidth: 0,
    sensorHeight: 0,
    focalLength: 0,
  });

  return (
    <div>
      <HeaderElement title={''} logo={''} />
      <EquipmentForm />
      <FovCalculator
        sensorWidth={equipment.sensorWidth}
        sensorHeight={equipment.sensorHeight}
        focalLength={equipment.focalLength}
      />
      <FooterElement title={''} logo={''} ></FooterElement>
    </div>
  );
};

export default HomePage;
