import React, { useState, useEffect, useRef } from 'react';
import telescopeData from './../telescopes.json';
import cameraData from './../cameras.json';
import dsoData from './../dso.json';
import { DSO } from '../types/Global';
import { Recommendation } from '../types/Global';

const EquipmentForm: React.FC = () => {
  const [telescope, setTelescope] = useState<string>('');
  const [bortle, setBortle] = useState<string>('');
  const [camera, setCamera] = useState('');
  const [filter, setFilter] = useState('None');
  const [isLoading, setIsLoading] = useState(false);
  const [recommendations, setRecommendations] = useState<Recommendation | null>(null);
  const [dsoSearch, setDsoSearch] = useState<string>(''); // DSO search input
  const [selectedDso, setSelectedDso] = useState<string>(''); // Store selected DSO
  const [showDropdown, setShowDropdown] = useState<boolean>(false); // Dropdown visibility
  const [dsoImage, setDsoImage] = useState<string>(''); // Image URL for the selected DSO

  // Load saved selections from localStorage on component mount
  useEffect(() => {
    const savedTelescope = localStorage.getItem('telescope');
    const savedCamera = localStorage.getItem('camera');
    const savedBortle = localStorage.getItem('bortle');
    const savedFilter = localStorage.getItem('filter');


    if (savedTelescope) setTelescope(savedTelescope);
    if (savedCamera) setCamera(savedCamera);
    if (savedBortle) setBortle(savedBortle);
    if (savedFilter) setFilter(savedFilter);

  }, []);

  // Filter DSO data based on user search input
  const filteredDso = dsoData.filter((dso: DSO) =>
    dso.OBJECT.toLowerCase().includes(dsoSearch.toLowerCase()) ||
    dso.OTHER.toLowerCase().includes(dsoSearch.toLowerCase()) ||
    dso.CON.toLowerCase().includes(dsoSearch.toLowerCase())
  );

  // Canvas reference
  const canvasRef = useRef<HTMLCanvasElement>(null);

  // Handle change in dropdown
  const handleTelescopeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setTelescope(event.target.value);
  };

  const handleBortleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setBortle(event.target.value);
  }

  const handleCameraChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCamera(event.target.value);
  }

  // Handle DSO search and dropdown interaction
  const handleDsoSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDsoSearch(event.target.value);
    setShowDropdown(true); // Show the dropdown as user types
  };

  // Load the full-frame image once we have recommendations
  useEffect(() => {
    if (recommendations) {
      const fullFrameImage = new Image();
      fullFrameImage.src = dsoImage; // Full frame image from SDSS or another source

      fullFrameImage.onload = () => {
        drawFovOverlay(fullFrameImage);
      };
    }
  }, [recommendations, dsoImage]); // Run when recommendations and image are updated

  // Draw the full-frame image and the FOV
  const drawFovOverlay = (fullFrameImg: HTMLImageElement) => {
    const canvas = canvasRef.current;
    if (!canvas) {
      console.error("Canvas not found");
      return;
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      console.error("Canvas context not found");
      return;
    }

    // Clear previous drawings
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.filter = 'grayscale(100%)';
    // Draw the full-frame image (assuming the full-frame image fits 2048x1080 canvas)
    ctx.drawImage(fullFrameImg, 0, 0, canvas.width, canvas.height);

    // Check recommendations
    if (!recommendations) {
      console.error("No recommendations available for FOV");
      return;
    }
    ctx.filter = 'none';
    // Calculate the telescope and camera FOV
    const { resolution, pixel_size, focal_length } = recommendations;
    const imageDimensions = calculateImageDimensions({ resolution, pixel_size, focal_length });
    // Log FOV dimensions for debugging
    console.log(`FOV Dimensions: Width: ${imageDimensions.widthArcminutes} arcminutes, Height: ${imageDimensions.heightArcminutes} arcminutes`);

    // Convert FOV from arcminutes to canvas pixels
    let fovWidth = (imageDimensions.widthArcminutes / 60) * canvas.width; // FOV in canvas pixels
    let fovHeight = (imageDimensions.heightArcminutes / 60) * canvas.height; // FOV in canvas pixels

    // Log calculated FOV in pixels for debugging
    console.log(`FOV in pixels: Width: ${fovWidth}, Height: ${fovHeight}`);

    // Check if FOV width or height is reasonable
    if (fovWidth > canvas.width || fovHeight > canvas.height) {
      console.warn("FOV exceeds canvas dimensions, adjusting FOV...");
    }

    console.log(fovWidth, fovHeight);

    fovWidth = fovWidth / 8;
    fovHeight = fovHeight / 8;

    // Draw the camera's field of view as a rectangle in red
    ctx.strokeStyle = 'red';
    ctx.lineWidth = 2;
    ctx.strokeRect(
      (canvas.width - fovWidth) / 2, // Center the FOV rectangle
      (canvas.height - fovHeight) / 2,
      fovWidth,
      fovHeight
    );
    const frameX = (canvas.width - fovWidth) / 2; // X-coordinate to center the frame
    const frameY = (canvas.height - fovHeight) / 2; // Y-coordinate to center the frame
    // Step 3: Draw the center crosshairs
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const crosshairSize = 20; // Size of crosshairs

    // Draw vertical line of the crosshairs
    ctx.beginPath();
    ctx.moveTo(centerX, centerY - crosshairSize); // Top part
    ctx.lineTo(centerX, centerY + crosshairSize); // Bottom part
    ctx.strokeStyle = 'red'; // Color for the crosshairs
    ctx.lineWidth = 2;
    ctx.stroke();

    // Draw horizontal line of the crosshairs
    ctx.beginPath();
    ctx.moveTo(centerX - crosshairSize, centerY); // Left part
    ctx.lineTo(centerX + crosshairSize, centerY); // Right part
    ctx.stroke();
    

    // Step 5: Zoom into the frame after it's drawn
    const marginFactor = 1.2; // Factor to keep some margin around the frame
    const zoomFactor = Math.min(canvas.width / (fovWidth * marginFactor), canvas.height / (fovHeight * marginFactor));

    // Step 6: Clear the canvas and apply zoom by scaling the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas

    // Set up the transformation for zoom
    ctx.save(); // Save current state
    ctx.translate(centerX - (centerX * zoomFactor), centerY - (centerY * zoomFactor)); // Translate to zoom
    ctx.scale(zoomFactor, zoomFactor); // Apply scaling

    // Redraw everything (image, frame, crosshairs) with the zoom applied

    // Draw the greyscaled image again after the transformation
    ctx.filter = 'grayscale(100%)';
    ctx.drawImage(fullFrameImg, 0, 0, canvas.width, canvas.height);
    ctx.filter = 'none';

    // Redraw the red frame (FOV rectangle)
    ctx.strokeStyle = '#FF4D4D';
    ctx.lineWidth = 1;
    ctx.strokeRect(frameX, frameY, fovWidth, fovHeight);

    // Redraw the crosshairs
    ctx.beginPath();
    ctx.moveTo(centerX, centerY - crosshairSize);
    ctx.lineTo(centerX, centerY + crosshairSize);
    ctx.strokeStyle = '#FF4D4D';
    ctx.lineWidth = 1;
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(centerX - crosshairSize, centerY);
    ctx.lineTo(centerX + crosshairSize, centerY);
    ctx.stroke();

    // Restore the context to its original state (removes scaling and translation)
    ctx.restore();
    
  };

  // Calculate image dimensions for FOV
  const calculateImageDimensions = (recommendations: {
    resolution: string;
    pixel_size: number;
    focal_length: number;
  }) => {
    const [widthPixels, heightPixels] = recommendations.resolution.split('x').map(Number);
    const pixelScale = (206.265 * recommendations.pixel_size) / recommendations.focal_length;
    const widthArcminutes = (pixelScale * widthPixels) / 60;
    const heightArcminutes = (pixelScale * heightPixels) / 60;
    return { widthArcminutes, heightArcminutes, pixelScale };
  };

  // Handle DSO selection from the dropdown
  const handleDsoSelect = (dso: DSO) => {
    setDsoSearch(dso.OBJECT); // Set the selected DSO name in the input
    setSelectedDso(dso.OBJECT); // Set the selected DSO for submission
    setShowDropdown(false); // Hide the dropdown
  };

  const convertRaToDecimal = (raString: { split: (arg0: string) => { (): any; new(): any; map: { (arg0: (string: string) => number): [any, any]; new(): any; }; }; }) => {
    const [hours, minutes] = raString.split(' ').map(parseFloat);
    const raDegrees = (hours + minutes / 60) * 15;
    return raDegrees.toFixed(4); // Returns a string with 4 decimal places
  };

  const convertDecToDecimal = (decString: { startsWith: (arg0: string) => any; replace: (arg0: string, arg1: string) => { (): any; new(): any; split: { (arg0: string): { (): any; new(): any; map: { (arg0: (string: string) => number): [any, any]; new(): any; }; }; new(): any; }; }; }) => {
    const sign = decString.startsWith('-') ? -1 : 1;
    const [degrees, minutes] = decString.replace('+', '').split(' ').map(parseFloat);
    const decDegrees = sign * (Math.abs(degrees) + minutes / 60);
    return decDegrees.toFixed(4); // Returns a string with 4 decimal places
  };

  // Fetch DSO image URL with zoomed-out scale
  const fetchSdssImageUrl = (
    ra: any, dec: any, objectHeightArcminutes: number, width: number, height: number
  ) => {
    // Step 1: Calculate object height in arcseconds
    const objectHeightArcseconds = objectHeightArcminutes * 60; // 1 arcminute = 60 arcseconds

    // Step 2: Calculate desired image height in arcseconds (1.5x object height)
    const desiredImageHeightArcseconds = objectHeightArcseconds * 2;

    // Step 3: Calculate the appropriate scale (arcseconds per pixel)
    const scale = desiredImageHeightArcseconds / height;

    // Convert RA and Dec to decimal for the SDSS API
    const raDecimal = convertRaToDecimal(ra);
    const decDecimal = convertDecToDecimal(dec);

    // Step 4: Construct the full URL with the calculated scale, width, and height
    const baseUrl = 'https://skyserver.sdss.org/dr16/SkyServerWS/ImgCutout/getjpeg';
    return `${baseUrl}?ra=${raDecimal}&dec=${decDecimal}&scale=${scale}&width=${width}&height=${height}`;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {

      const response = await fetch('https://astrokey-79bdd6233b60.herokuapp.com/api/recommendations/create', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ telescope, camera, filter, bortle, selectedDso })
      });
      const data = await response.json();

      // Assuming the response contains the string as shown in your example
      const recommendationString = data.recommendations;

      // Extract JSON from the `recommendations` string
      const jsonMatch = recommendationString.match(/```json\n([\s\S]*?)```/);

      if (jsonMatch && jsonMatch[1]) {
        const recommendationsJson: Recommendation = JSON.parse(jsonMatch[1]);
        setRecommendations(recommendationsJson); // Store the parsed JSON as an object
      } else {
        console.error('No valid JSON found in the response');
      }

      // Save the user's selections to localStorage
      localStorage.setItem('telescope', telescope);
      localStorage.setItem('camera', camera);
      localStorage.setItem('bortle', bortle);
      localStorage.setItem('filter', filter);
      localStorage.setItem('dso', selectedDso);

    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (recommendations) {
      setDsoImage(''); // Clear the previous image
      const selectedDsoData = dsoData.find(dso => dso.OBJECT === selectedDso);

      if (selectedDsoData) {

        const size = parseInt(selectedDsoData.SIZE_MAX, 10); // DSO size in arcminutes
        const imageUrl = fetchSdssImageUrl(
          selectedDsoData.RA,
          selectedDsoData.DEC,
          size, // DSO's width in arcminutes
          3048,
          3048
        );
        setDsoImage(imageUrl);
      } else {
        console.error('Selected DSO data not found');
      }
    }
  }, [recommendations]); // This useEffect will run when recommendations is updated

  const isFormComplete = telescope && camera && bortle && selectedDso && filter;

  return (
    <div className="main">
      <div className="app-description container">
        <div className="app-description__container">
          <div className="app-description__left">
            <h2>Welcome to AstroKey</h2>
            <p>
                AstroKey is your ultimate tool for astrophotography planning and equipment optimization. Whether you're a seasoned astrophotographer or just getting started, our app helps you capture stunning images of deep space objects with ease.
            </p>
            <h3>How It Works</h3>
            <p>
                Simply select your telescope, camera, and filter setup, then choose a deep space object you'd like to photograph. AstroKey will provide tailored exposure recommendations, calibration frames, and more. You can also visualize how your equipment will capture the object in real-time and fine-tune your setup for the best results.
            </p>
            <p>
              <b>More features coming soon! Stay tuned for updates.</b>
            </p>
          </div>
          <div className="app-description__right">
          <h3>Features</h3>
            <ul>
                <li>
                    <strong>Equipment Recommendations:</strong> Get personalized session recommendations for your telescope, camera, and filter setup to achieve optimal results for specific deep space objects.
                </li>
                <li>
                    <strong>Field of View Visualization:</strong> See how your selected equipment frames your target object, with accurate field-of-view calculations to ensure you're capturing the perfect shot.
                </li>
                <li>
                    <strong>Real-time Imaging Data:</strong> Access the latest image data from Sky Survey databases in FOV, helping you refine your focus on what matters most.
                </li>
            </ul>
          </div>   
        </div>
      </div>
      <div className="equipment-form container">
        <form onSubmit={handleSubmit}>
          <div className="equipment-form__wrapper">
            <div className="equipment-form__form-group">
              <label htmlFor="telescope-dropdown">Telescope:</label>
              <select
                id="telescope-dropdown"
                value={telescope}
                onChange={handleTelescopeChange}
              >
                <option value="" disabled>Select a telescope</option>
                {telescopeData.telescopes.map((telescope, index) => (
                  <option key={index} value={telescope}>
                    {telescope}
                  </option>
                ))}
              </select>
            </div>
            <div className="equipment-form__form-group">
              <label htmlFor="camera-dropdown">Camera:</label>
              <select
                id="camera-dropdown"
                value={camera}
                onChange={handleCameraChange}
              >
                <option value="" disabled>Select a Camera</option>
                {cameraData.cameras.map((camera, index) => (
                  <option key={index} value={camera}>
                    {camera}
                  </option>
                ))}
              </select>
            </div>
            <div className="equipment-form__form-group">
              <label htmlFor="bortle-dropdown">Bortle Level:</label>
              <select
                id="bortle-dropdown"
                value={bortle}
                onChange={handleBortleChange}
              >
                <option value="" disabled>Select Bortle Level</option>
                <option key={1} value={"Bortle 1"}>Bortle 1</option>
                <option key={2} value={"Bortle 2"}>Bortle 2</option>
                <option key={3} value={"Bortle 3"}>Bortle 3</option>
                <option key={4} value={"Bortle 4"}>Bortle 4</option>
                <option key={5} value={"Bortle 5"}>Bortle 5</option>
                <option key={6} value={"Bortle 6"}>Bortle 6</option>
                <option key={7} value={"Bortle 7"}>Bortle 7</option>
                <option key={8} value={"Bortle 8"}>Bortle 8</option>
              </select>
            </div>
            <div className="equipment-form__form-group">
              <label>Filter:</label>
              <input
                type="text"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                placeholder="Enter filter"
              />
            </div>
            <div className="equipment-form__form-group">
              <label htmlFor="dso-search">Search DSO:</label>
              <input
                id="dso-search"
                type="text"
                value={dsoSearch}
                onChange={handleDsoSearchChange}
                onFocus={() => setShowDropdown(true)} // Show dropdown on focus
                placeholder="Search DSO by name, other, constellation..."
              />
              {showDropdown && dsoSearch && (
                <ul className="dropdown-list">
                  {filteredDso.slice(0, 10).map((dso: DSO, index: number) => (
                    <li key={index} onClick={() => handleDsoSelect(dso)}>
                      {dso.OBJECT} - {dso.OTHER}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="equipment-form__button-wrapper">
              {isFormComplete && !isLoading && <button type="submit">Get Recommendations</button>}
              { isLoading && <button type="submit" disabled>Loading...</button>}
              { !isFormComplete && <button type="submit" className="disabled" disabled>Get Recommendations</button>}
            </div>
          </div>
        </form>
      </div>
      {isLoading && <div className="loader-wrapper"><div className="loader"></div></div>} {/* Spinning loader */}
      {!isLoading && recommendations && (
        <div className="equipment-form__results container">
          <div className="recommendations">
            <div className="recommendations-block">
              <h3>Recommendations:</h3>
              <p><b></b>Total Integration Time (hours): {recommendations.total_integration_time_minutes / 60}</p>
              <p>Camera Gain/ISO: {recommendations.camera_gain}</p>
            </div>
            <div className="recommendations-block">
              <h3>Sub Exposures:</h3>
              <p>Number of Sub Exposures: {recommendations.sub_exposures.number}</p>
              <p>Sub Exposure Length (seconds): {recommendations.sub_exposures.exposure_length_seconds}</p>
            </div>
            <div className="recommendations-block">
              <h3>Calibration Frames:</h3>
              <p>Dark Frames: {recommendations.calibration_frames.dark_frames.number} (Exposure: Same as sub exposures)</p>
              <p>Flat Frames: {recommendations.calibration_frames.flat_frames.number} (Exposure: {recommendations.calibration_frames.flat_frames.exposure_length_seconds} seconds) - This can vary wildly, aim to have you're histogram peak totally centered.</p>
              <p>Bias Frames: {recommendations.calibration_frames.bias_frames.number} (Exposure: {recommendations.calibration_frames.bias_frames.exposure_length_seconds} seconds)</p>
            </div>
          </div>
          <div className="field-of-view">
            <h3>Field of View</h3>
            {/* Canvas for FOV */}
            <canvas ref={canvasRef} width="2048" height="2048"></canvas>
          </div>
        </div>
      )}
    </div>
  );
};

export default EquipmentForm;
