import React from 'react';
import { Header } from '../types/Global';

const HeaderElement: React.FC<Header> = () => {


  return (
    <div className="header">
      <div className="header__container container">
        <div className="header__logo">
          <img src="/astrokey.svg" alt="astrokey Logo"></img>
        </div>
      </div>
    </div>
  );
};

export default HeaderElement;