import React from 'react';

interface FovCalculatorProps {
  sensorWidth: number;
  sensorHeight: number;
  focalLength: number;
}

const FovCalculator: React.FC<FovCalculatorProps> = ({ sensorWidth, sensorHeight, focalLength }) => {
  const calculateFOV = () => {
    const fovWidth = (sensorWidth / focalLength) * 57.3;
    const fovHeight = (sensorHeight / focalLength) * 57.3;
    return { fovWidth, fovHeight };
  };

  const { fovWidth, fovHeight } = calculateFOV();

  return (
    <div>
      
    </div>
  );
};

export default FovCalculator;