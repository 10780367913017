import React from 'react';
import { Footer } from '../types/Global';

const FooterElement: React.FC<Footer> = () => {


  return (
    <div className="footer">
      <div className="footer__container container">
        <div className="footer__logo">
          <p><span>&copy; 2024 AstroKey</span> - Images Generated Courtesy of SDSS/Skyserver and DSS</p>
        </div>
      </div>
    </div>
  );
};

export default FooterElement;